import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Table from 'react-bootstrap/Table';
import { useAuth } from "react-oidc-context";
import AbsenceModal from "./AbsenceModal";
import { sortByDate, statusColor } from "../../../utils";

// import interfaces
import { Absence, Status } from "../../../interfaces/AbsenceInterface";
import { toast } from "react-toastify";

function AbsenceStudentTable(props: {
    setFilteredAbsences: any; studentSelected: string, filteredAbsences: Absence[] | null | undefined
}) {
    const [absences, setAbsences] = useState<Absence[] | null>(null);
    const [absenceModal, setAbsenceModal] = useState<Absence | null>(null);
    const auth = useAuth();

    useEffect(() => {
        axios.get<Absence[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/absences/${auth.user?.profile.preferred_username}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => setAbsences(sortByDate(response.data, 'courseDate')), error => {
                toast.error("Une erreur est survenue lors de la récupération des absences");
            });
    }, [auth.user?.access_token, auth.user?.profile.preferred_username]);

    useEffect(() => {
        props.setFilteredAbsences(absences?.filter(absence => props.studentSelected === "" || absence.studentLogin === props.studentSelected));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [absences, props.studentSelected]);

    // Check if the absence is in the time left for justification
    // 172800000 = 48 hours in milliseconds
    function isTimeLeftForJustification(courseDate: string) {
        const date = new Date(courseDate);
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        return diff > 0 && diff < 172800000;
    }

    // Render the action button depending on the status of the absence
    function renderActionButton(absence: Absence, setAbsenceModal: (absence: Absence | null) => void) {
        if (absence.status !== Status.NON_JUSTIFIE) {
            return (
                <Button variant="primary" onClick={() => setAbsenceModal(absence)}>
                    Détails
                </Button>
            );
        } else if (isTimeLeftForJustification(absence.courseDate)) {
            return (
                <Button variant="primary" onClick={() => setAbsenceModal(absence)}>
                    Justifier
                </Button>
            );
        }
        return null;
    }

    if (!absences)
    {
        return (<Spinner animation="border" variant="primary" />);
    }

    return (<>
        <Table striped className="table-borderless rounded my-2 overflow-hidden">

            <thead className="text-center">
                <tr className="border-bottom">
                    <th className="fs-4">Cours</th>
                    <th className="fs-4">Date</th>
                    <th className="fs-4">Temporalité</th>
                    <th className="fs-4">Statut</th>
                    <th></th>
                </tr>
            </thead>
            <tbody className="text-center align-middle">
                {props.filteredAbsences?.map((absence, index) => (
                    <tr key={index}>
                        <td>{absence.courseName}</td>
                        <td>{new Date(absence.courseDate).toLocaleDateString('FR-fr')}</td>
                        <td>{absence.declarationId ? 'a priori' : 'a posteriori'}</td>
                        <td className="align-items-center">
                            <span className={`d-block mx-auto w-50 p-2 rounded text-white ${statusColor(absence.status)}`}>
                                {absence.status.toString()}
                            </span>
                        </td>
                        <td>
                            {renderActionButton(absence, setAbsenceModal)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <AbsenceModal absence={absenceModal} setAbsence={setAbsenceModal} />
    </>
    );
}

export default AbsenceStudentTable;
