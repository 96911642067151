import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import axios from 'axios';
import { Button, Modal, Form } from "react-bootstrap";
import { Course, CourseWithMean } from "../../../../interfaces/CourseInterface";
import Select from "react-select";
import { toast } from "react-toastify";

export default function OpenAttendanceModal(props: {
    setModalType: (modalType: string) => void; setIsPresent: (isPresent: boolean) => void; show: boolean; onHide: (() => void) | undefined;
}) {
    const [courseList, setCourseList] = useState<CourseWithMean[]>([]);
    const [courseSelected, setCourseSelected] = useState<{value : number, label : string} | null>(null);
    const auth = useAuth();
    const promotion = auth.user?.profile.promotion;

    // Get the courses list
    useEffect(() => {
        axios.get<CourseWithMean[]>(`${process.env.REACT_APP_SCOLARITE_API_URL}/course?promotion=${auth.user?.profile.promotion}`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            setCourseList(response.data);
        }).catch(error => {
            console.error(error);
            toast.error("Une erreur est survenue")
        });
    }, [auth.user?.access_token, auth.user?.profile.promotion]);

    // Create a new attendance record from the form
    function createAttendance(event: any) {
        event.preventDefault();

        axios.post(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/presence/create-attendance-record`, {
            courseName: courseSelected?.label,
            courseId: courseSelected?.value,
            date: new Date().toISOString(),
            promotion: promotion,
        },
        {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            props.setIsPresent(true);
            window.location.reload();
        }).catch(error => {
            console.error(error);
            toast.error("Une erreur est survenue");
        });
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ouvrir une fiche de présence
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={createAttendance}>
                <Modal.Body>
                    <div className='justify-content-center d-flex flex-column'>
                        <h4 className="text-center">Date :</h4>
                        <p>{new Date().toLocaleString('FR-fr')}</p>
                    </div>
                    <div>
                        <h4 className="text-center py-2">Choisir un cours</h4>
                        <Select
                            name="courseId"
                            onChange={(selectedCourse: any) => setCourseSelected(selectedCourse)}
                            options={courseList.map((course: Course) => ({ value: course.courseId, label: course.courseName }))}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Créer
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
