import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import tickImg from '../../../../assets/icons/tick.svg';
import openAttendanceImg from '../../../../assets/icons/openAttendance.svg';
import detailAttendanceImg from '../../../../assets/icons/detailAttendance.svg';
import axios from 'axios';
import { Button } from "react-bootstrap";
import { Group } from "../../../../interfaces/AuthentificationInterface";
import OpenAttendanceModal from "./openAttendanceModal";
import DetailsAttendanceModal from "./detailsAttendanceModal";
import WarningModal from "./WarningModal";
import { toast } from "react-toastify";

export interface PresenceState {
    course?: string,
    date?: Date,
    attendance: boolean,
    isPresent?: boolean
}

function Presence() {
    const [presence, setPresence] = useState<PresenceState>({ attendance: false });
    const [isPresent, setIsPresent] = useState<boolean>(false);
    const [timeLeftForAttendance, setTimeLeftForAttendance] = useState<number>(0);
    const [attendanceStatus, setAttendanceStatus] = useState<string>('');
    const [showWarning, setShowWarning] = useState<boolean>(false);

    // vars for warning modal
    const warningPrimaryText = "Check présence";
    const warningSecondaryText = "Toute déclaration frauduleuse entrainera une sanction disciplinaire.";
    const warningButtonText = "Confirmer";

    
    // Used to display the modals
    // Values: "Open" | "Details" | "null"
    const [modalType, setModalType] = useState<string | null>(null);

    // Auth
    const auth = useAuth();
    const userGroup = auth.user?.profile.group as Array<Group>;

    // Get if an attendance is open and if the user is present
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ABSENCE_API_URL}/attendance/get-attendance-record/${auth.user?.profile.preferred_username}`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            setPresence(response.data);
            setIsPresent(response.data.isPresent ?? false);
        }).catch(error => {
            console.error(error);
            toast.error("Une erreur est survenue");
        });
    }
    , [isPresent, auth.user]);

    // Get the time left before the attendance closes
    useEffect(() => {
        const presenceDate = new Date(presence.date ?? '');
        const currentTime = new Date();
        
        // 5 minutes = 300 000 milliseconds
        // 1 minute = 60 000 milliseconds
        const timeDifference = (presenceDate.getTime() + 300000) - currentTime.getTime();

        setTimeLeftForAttendance(Math.floor(timeDifference / 60000));
        if (timeDifference > 60000 && timeDifference <= 300000) {
            setAttendanceStatus(`Fermeture dans ${timeLeftForAttendance} minutes !`);
        } else if (timeDifference > 0 && timeDifference <= 60000) {
            setAttendanceStatus("Plus qu'une minute !");
        } else {
            setAttendanceStatus('Prise de présence fermée !');
        }
    }, [timeLeftForAttendance, presence.date]);

    // Set the user as present
    function handlePresence() {
        axios.get(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/presence/recordAttendance/${auth.user?.profile.preferred_username}`, { headers: { Authorization: `Bearer ${auth.user?.access_token}` }
        }).then(response => {
            setIsPresent(true);
            toast.success("Ta présence à été validée");
        }).catch(error => {
            console.error(error);
            toast.error("Une erreur est survenue");
        });
    }

    return (
        <>
            <div className="card">
                <div className="card-body d-flex flex-column">
                    <div className="justify-content-between d-flex">
                        <h3 className="card-title fw-bold">Présence</h3>
                        {userGroup.includes(Group.Representative) && (
                            <>
                                <Button variant="light" onClick={() => (presence.attendance ? setModalType("Details") : setModalType("Open"))}>
                                    <img src={`${presence.attendance ? detailAttendanceImg : openAttendanceImg}`} width="25" height="25" alt="Présence" />
                                </Button>
                            </>
                        )}
                    </div>
                    {presence.attendance ? (
                        <div className="py-2 mt-2">
                            <div className="col-3 fs-5 fw-semibold">
                                {presence.course}
                            </div>
                            <div className="justify-content-between d-flex mt-2">
                                <div className="text-secondary">
                                    {attendanceStatus}
                                </div>
                                <Button className={`p-0 ${(timeLeftForAttendance > 0 && presence.isPresent === false) ? 'active' : 'disabled'} ${presence.isPresent ? 'bg-secondary' : 'bg-primary'}`} onClick={() => setShowWarning(true)}>
                                    <img src={tickImg} alt="tick" width="30" height="30" />
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="card-text text-center text-secondary py-3">Pas de présence en cours</div>
                    )}
                </div>
            </div>

            <OpenAttendanceModal setModalType={setModalType} setIsPresent={setIsPresent} show={modalType === "Open"} onHide={() => setModalType(null)} />
            <DetailsAttendanceModal modalType={modalType} setModalType={setModalType} setPresence={setPresence} show={modalType === "Details"} onHide={() => setModalType(null)} />
            <WarningModal show={showWarning} onHide={() => setShowWarning(!showWarning)} primaryText={warningPrimaryText} secondaryText={warningSecondaryText} buttonText={warningButtonText} onClickButton={() => {handlePresence(); setShowWarning(false);}}/>
        </>
    );
}

export default Presence;
