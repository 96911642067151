import React, { useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { Declaration } from "../../../interfaces/AbsenceInterface";
import { Status } from "../../../interfaces/AbsenceInterface";
import { useAuth } from "react-oidc-context";
import { toast } from "react-toastify";

export function DetailsModal(props: { declarationModal: Declaration | null, setDeclarationModal: (declaration: Declaration | null) => void }) {
    const auth = useAuth();
    const [isSubmitButtonDisable, SetSubmitButtonIsDisabled] = useState(false);

    const handleValidation = (isValid: boolean) => {
        if (props.declarationModal) {
            SetSubmitButtonIsDisabled(true);
            const body = {
                "declarationID" : props.declarationModal.declarationId.toString(),
                "status" : isValid.toString()
            };
            axios.post(`${process.env.REACT_APP_ABSENCE_API_URL}/declaration/update-declaration`, body,
                { 
                    headers: 
                    { 
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    }
                })
                .then(response => {
                    if (response.status === 201) {
                        props.setDeclarationModal(null);
                    }
                    SetSubmitButtonIsDisabled(false);
                }, error => {
                    toast.error("Une erreur est survenue");
                });
        } else {
            console.log("Validation failure");
        }
    };

    return (
        <Modal show={props.declarationModal !== null} onHide={() => props.setDeclarationModal(null)}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between my-1">
                    <span className="fw-bold">Début : {new Date(props.declarationModal?.startDate ?? '').toLocaleDateString('FR-fr')}</span>
                    <span className="fw-bold">Fin : {new Date(props.declarationModal?.endDate ?? '').toLocaleDateString('FR-fr')}</span>
                </div>
                <span className="fw-bold">Description :</span>
                <textarea disabled className="w-100" defaultValue={props.declarationModal?.description} rows={6}/>
                <span className="fw-bold">Pièce jointe : {props.declarationModal?.hasAttachement ? 'Oui' : 'Non'}</span>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                {
                    props.declarationModal?.status === Status.EN_ATTENTE &&
                    <>
                        <Button variant="success" className="me-2"
                            onClick={() => handleValidation(true)} disabled={isSubmitButtonDisable} >Valider la déclaration</Button>
                        <Button variant="danger" className="ms-2"
                            onClick={() => handleValidation(false)} disabled={isSubmitButtonDisable} >Refuser la déclaration</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}
