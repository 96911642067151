import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import editIcon from '../../../../assets/icons/edit.svg';
import { Absence, Status } from "../../../../interfaces/AbsenceInterface";
import AbsenceModal from '../../absence/AbsenceModal';
import HelpButtonModal from '../../help/helpModal';
import { toast } from 'react-toastify';

function AbsenceStudent() {
    const auth = useAuth();
    const [absences, setAbsences] = useState<Absence[]>([]);
    const [absenceModal, setAbsenceModal] = useState<Absence | null>(null);
    const description = "Ici vous retrouverez l'ensemble de vos absences ainsi que vos justifications. Pensez à justifier ! Une question ? Faites le nous savoir -> aide dans la barre de navigation.";
    const [screensize, setScreensize] = useState<'Small' | 'Big'
     >(window.innerWidth > 768 ? 'Big' : 'Small');


    function colorOfAbsence() {
        if (absences.length === 0) {
            return "green";
        }
        // go from green when 0 to red when 10
        const color = Math.round(absences.length * 255 / 10);
        return `rgb(${color}, ${255 - color}, 0)`;
    }

    // when screen size is small, change the column size
    useState(() => {
        window.addEventListener('resize', () => {
            setScreensize(window.innerWidth > 768 ? 'Big' : 'Small');
        });
    });

    useEffect(()=> {
        axios.get<Absence[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/absences/${auth.user?.profile.preferred_username}`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            setAbsences(response.data);
        }
        ).catch(error => {
            console.error(error);
            toast.error("Une erreur est survenue lors de la récupération des absences");
        });
    }, [auth.user?.profile.preferred_username, auth.user?.access_token]);

    function statusColor(status: Status) {
        switch (status) {
        case Status.EXCUSE:
            return "text-success";
        case Status.NON_JUSTIFIE:
            return "text-danger";
        case Status.VU:
            return "text-warning";
        default:
            return "text-black-50";
        }
    }

    function lastAbsence(eleves_absents: Absence[]) {
        const last_absences = eleves_absents.sort((a, b) => new Date(b.courseDate).getTime() - new Date(a.courseDate).getTime()).slice(0, 3);
        return (
            <div className='container'>
                {last_absences.map((eleve, index) => (
                    <div key={index} className="row py-3 border-bottom align-items-center">
                        <div className={`col-${screensize === 'Big' ? '4' : '3'} fw-semibold p-0 fs-${screensize === 'Big' ? '5' : '6'} ${statusColor(eleve.status)}`}>
                            {eleve.courseName}
                        </div>
                        <div className={`col-${screensize === 'Big' ? '5' : '6'} fs-6 fw-semibold text-black-50`}>
                            {new Date(eleve.courseDate).toLocaleDateString()}
                        </div>
                        {/*172,800,000 correspond to 48 hours */
                            eleve.status === Status.NON_JUSTIFIE && (new Date(eleve.courseDate).getTime() + 172800000 >= new Date().getTime()) &&
                            <div className="col-3">
                                <Button
                                    variant='light'
                                    className="rounded-circle p-2"
                                    style={{ width: '50px', height: '50px' }}
                                    onClick={() => setAbsenceModal(eleve)}
                                >
                                    <Image src={editIcon} fluid className=" rounded-circle" alt="" />
                                </Button>
                                <AbsenceModal absence={absenceModal} setAbsence={setAbsenceModal} />
                            </div>}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <Card className="card">
            <Card.Body className="d-flex flex-column p-4">
                <Card.Title className="d-flex justify-content-between">
                    <h3 className="fw-bold">Absences</h3>
                    <h3 className="fw-bold" style={{color: `${colorOfAbsence()}`}}

                    >{absences.length}</h3>
                </Card.Title>
                {absences.length ? (
                    lastAbsence(absences)
                ) : (
                    <div className="card-text text-center">{`Pas d'absences`}</div>
                )}
                { screensize === 'Big' &&
                <div className="d-flex flex-column p-2">
                    <Link to="/absences">
                        <Button className='w-100'>Voir toutes les absences</Button>
                    </Link>
                </div>
                }
                <div className='d-flex justify-content-end'>
                    <HelpButtonModal content={description} isCard={true}></HelpButtonModal>
                </div>
            </Card.Body>
        </Card>
    );
}

export default AbsenceStudent;
