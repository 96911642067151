import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';

// import interfaces
import { Absence, Status } from '../../../interfaces/AbsenceInterface';
import { toast } from 'react-toastify';

function AbsenceModal(props: { absence: Absence | null; setAbsence: any }) {
    const auth = useAuth();
    const [isSubmitButtonDisable, SetSubmitButtonIsDisabled] = useState(false);

    function handleJustification(event: any) {
        SetSubmitButtonIsDisabled(true);
        event.preventDefault();
        const form = event.currentTarget;

        // formData[1] = id
        // formData[2] = description
        // formData[3] = file
        axios.post(`${process.env.REACT_APP_ABSENCE_API_URL}/absences/justify`, {
            studentLogin: auth.user?.profile.preferred_username,
            absenceId: form[1].value,
            description: form[2].value,
            file: form[3].files[0],
        },
        {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            props.setAbsence(null);
            toast.success("Votre justification a bien été soumise !");
            SetSubmitButtonIsDisabled(false);
            window.location.reload();
        }).catch(error => {
            console.error(error);
            if (error.response.data.errors === "Invalid file type.") {
                toast.error("Le fichier selectionné n'est pas supporté. Les fichiers peuvent être des png, jpeg, jpg, pdf ou heic");
            }
            else if (error.response.status === 413 || error.response.data.errors === "File size exceeds 1MB limit.") {
                toast.error("Le fichier selectionné est trop volumineux. Il ne doit pas être superieur à 1MB.");
            }
            else {
                toast.error("Une erreur est survenue");
            }
            SetSubmitButtonIsDisabled(false);
        });
    }

    return (
        <Modal animation={false} show={props.absence !== null} onHide={() => props.setAbsence(null)}>
            <Form onSubmit={handleJustification}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>{"Justification d'Absence"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="absenceForm.id">
                        <Form.Control hidden readOnly className="fw-bold" value={props.absence?.absenceId} />
                    </Form.Group>
                    <div className="d-flex justify-content-between my-1">
                        <span className="fw-bold">Cours : {props.absence?.courseName}</span>
                        <span className="fw-bold">Date : {new Date(props.absence?.courseDate ?? '').toLocaleDateString('FR-fr')}</span>
                    </div>
                    <Form.Group controlId="absenceForm.description">
                        <Form.Label className="fw-bold">Description :</Form.Label>
                        <Form.Control as="textarea" disabled={props.absence?.status !== Status.NON_JUSTIFIE} className="w-100" defaultValue={props.absence?.description} rows={6} />
                    </Form.Group>
                    {props.absence?.status === Status.NON_JUSTIFIE ?
                        
                        (<Form.Group className="py-2" id="absenceForm.file">
                            <Form.Text>Les types de fichiers supportés sont : png, jpeg, jpg, pdf ou heic.</Form.Text>
                            <br/>
                            <Form.Text>Un fichier ne peut excéder 1 Mo.</Form.Text>
                            <Form.Control type="file" />
                        </Form.Group>
                        ) : (<span className="fw-bold">Pièce jointe : {props.absence?.hasAttachement ? 'Oui' : 'Non'}</span>)}
                </Modal.Body>
                <Modal.Footer className="border-0">
                    {props.absence?.status === Status.NON_JUSTIFIE &&
                        <Button variant="primary" type="submit" disabled={isSubmitButtonDisable}>
                            {isSubmitButtonDisable ? (
                                'Veuillez patentier...'
                            ) : 'Soumettre' }
                        </Button>}
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default AbsenceModal;
